import axios from 'axios';

axios.defaults.withCredentials = false;

const state = {
    userInfo: null,
    error: null,
    isAuthenticated: false,
    subscriptionInfo: null,
    instructorInfo: null,
    token: null // Add token property to store the token
};

const getters = {
    userInfo: state => state.userInfo,
    loginError: state => state.error,
    isAuthenticated: state => state.isAuthenticated,
    subscriptionInfo: state => state.subscriptionInfo,
    instructorInfo: state => state.instructorInfo,
    token: state => state.token // Getter for accessing the token
};

const actions = {
    async loginUser({ commit }, credentials) {
        fetch(`${process.env.VUE_APP_API}/login`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            requiredStatus: 'ok',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'User-Agent': navigator.userAgent,
            },
            body: JSON.stringify(credentials)
        })
        .then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })).then(res => {
                if (res.data.token_key) {
                    console.log("User token:", res.data.token_key); 
                    commit("setUserInfo", res.data);
                    console.log(res.data.token_key);    
                    commit("setToken", res.data.token_key); 
                } else {
                    commit("errMessage", res.data.error)
                    setTimeout(() => {
                        commit("errMessage", "")
                    }, 3000)
                }
            }));
    },
    async logOutUser({ commit, state }) {
        try {
            const token = state.token; 
            if (token) {
                console.log("User token:", token); 
                const response = await axios.get(`${process.env.VUE_APP_API}/singout`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                commit("logout");
                console.log("User logged out successfully");

                return response.data;
            } else {
                console.error("No token found");
            }
        } catch (error) {
            console.error("Error logging out:", error);
        }
    },

    async subcription({ commit }, subdata) {
        // Subscription action
    },

    async instructordetails({ commit }, subdata) {
        // Instructor details action
    }
};

const mutations = {
    setUserInfo: (state, data) =>  { 
        state.userInfo = data ; 
        state.isAuthenticated = true;
        state.error = null;
    },
    errMessage: (state, data) => { 
        state.error = data
    },
    logout: (state) => { 
        state.userInfo = null; 
        state.isAuthenticated = false;
        state.subscriptionInfo = null,
        state.instructorInfo = null;
        state.token = null; // Clear the token when logging out
    },
    setToken: (state, token) => {
        state.token = token; // Store the token in the store
    },
    subdetails: (state, data) => {
        state.subscriptionInfo = data;
    },
    instructorInfos: (state, data) => {
        state.instructorInfo = data;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
