        <template>
        <Navigation />
        <div class='overflow-y-auto h-screen  flex' style="scrollbar-color:gray white ">
        <internLaftNav actived='StudentAttendance' />
            <div  class="flex flex-col w-full">
                        <div class=" inline-block overflow-x-auto  h-5/6 w-full ">
                            <table class="leading-normal w-full text-base" >
                                <thead>
                                    <tr class="bg-gray-100 border-b-2 border-teal-700 text-teal-700 tracking-wider">
                                        <th class=" flex flex-col-reverse lg:flex-row items-center">
                                        <div>Name</div>  <input type="search" v-model="searchString" placeholder="Search" class="w-full lg:w-3/4 rounded-full border-teal-600 px-0.5 lg:px-2 h-6 lg:h-8 border mx-0.5 lg:mx-6"/>
                                        </th>
                                        <th class="text-medium">
                                            Email
                                        </th>
                                        <th class=" ">
                                            Active
                                        </th>
                                        
                                        <th class=" ">
                                            Last Login
                                        </th>
                                        <th class=" ">
                                            Last Logout
                                        </th>
                                        <!-- <th class=" ">
                                            Instructor
                                        </th> -->
                                    </tr>
                                </thead>

                                
                                <tbody v-if="this.studentInfo != null" class="px-2 lg:px-3 py-4 lg:py-4 border border-gray-200 bg-white text-md">
                                    <tr v-for="student in studentdetails" :key="student" >
                                        <td class=" border border-gray-200"><router-link class="text-gray-700" :to="{ name: 'attendancedetail', params: { studentId: student.id } }">{{student.display_name}}</router-link></td>
                                        

                                        <td class=" border border-gray-200">
                                            {{student.email}}
                                        </td>
                                        <td v-if="student.is_active==true" class=" border border-gray-200 text-center">
                                            <span class="bg-green-300 px-2 rounded">Joined</span> 
                                        </td>
                                        <td v-if="student.is_active==false" class=" border border-gray-200 text-center">
                                            <span  class="bg-red-300 px-2 rounded">Invited</span> 
                                        </td>
                                   
                                        <td class=" border border-gray-200 text-center">
                                            {{student.login_time}}
                                        </td>
                                        <td class="border border-gray-200 text-center ">
                                            {{student.logout_time}}
                                        </td>
                                        <!-- <td class="border border-gray-200 text-center ">
                                            <button v-if="student.students_Status==true" @click="upgrade(student.id)" class="bg-teal-600 hover:bg-teal-700 text-white px-2 py-0.5 rounded">
                                                Upgrade
                                            </button>
                                        </td> -->

                                    </tr>
                                    
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>

        </div>

        </template>

        <script>
        import axios from "axios"
        import {mapGetters} from "vuex";
        import Navigation from "@/components/Navigation.vue"
        import internLaftNav from "@/views/organization/leftNavIntern.vue"
        import {
            ref,
            computed
        } from 'vue';
        export default {
    setup() {
        const searchString = ref('');
        const studentInfo = ref(null);

        const studentdetails = computed(() => {
            let result = studentInfo.value;

            if (searchString.value) {
                const search = searchString.value.toLowerCase().trim();
                result = studentInfo.value.filter(student => {
                    const nameMatch = student.display_name.toLowerCase().includes(search);
                    const emailMatch = student.email.toLowerCase().includes(search);
                    return nameMatch || emailMatch;
                });
            }

            return result;
        });

        return {
            searchString,
            studentInfo,
            studentdetails
        };
            },
            data(){
            return {
                actived:"active",
                
            
            }
            },
            components: {
                Navigation,
                internLaftNav
            },
            computed:mapGetters(["userInfo"]),

            created(){

                axios.get(`${process.env.VUE_APP_API}/login_history/?Key=${this.userInfo.token_key}`)
                .then(response =>{this.studentInfo=response.data.data
                console.log(this.studentInfo)
                    let uniqueRecords = []
                    let uniqueRecords_id = []
                    this.studentInfo.forEach(record => {
                        if (uniqueRecords_id.indexOf(record.students_email) === 1) {
                            uniqueRecords.push(record)
                            uniqueRecords_id.push(record.students_email)
                            this.studentInfo=uniqueRecords
                            
                        }
                    })
                })

            },
            // methods:{
            //     upgrade(id){
            //         let headerVal = {
            //                         "user_id"       :this.userInfo.organizes_id,
            //                         "student_id"    :id,
            //                     }
            //                     console.log(headerVal)
            //         axios.post(`${process.env.VUE_APP_API}/upgrade_instructor/?Key=${this.userInfo.token_key}`,headerVal)
            //         .then(response =>{
            //             console.log(response.data)
            //         })
            //     }
            // }

        }
        </script>

        <style scoped>
        th,tr,td,table{
        padding: 10px;
        }


        </style>
