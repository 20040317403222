import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
require('dotenv').config()

// Static pages
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import SignUp from "@/views/SignUp.vue";
import Pricing from "@/views/Pricing.vue";
import Python from "@/views/Python.vue";
import Rust from "@/views/Rust.vue";
import Courses from "@/views/CourseList.vue";
import Thanks from "@/views/Thanks.vue";
import CourseDetail from "@/views/CourseDetail.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import LeftNav from "@/components/LeftNav.vue";
import VideoRender from "@/views/VideoRender.vue";
import Payment from "@/views/Payment.vue";
import InstructorLayout from "@/views/instructor/Layout.vue";
import instructorCourse from "@/views/instructor/instructorCourse.vue";
import AddCourse from "@/views/instructor/AddCourse.vue";
import DashboardLayout from "@/views/dashboard/Layout.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import DashboardProfile from "@/views/dashboard/Profile.vue";
import DashboardSettings from "@/views/dashboard/Settings.vue";
import DashboardResource from "@/views/dashboard/Resources.vue";
import Success from "@/views/Success.vue";
import OrganizationDashboard from "@/views/organization/internDashboard.vue";
import internLaftNav from "@/views/organization/leftNavIntern.vue";
import MenterShip from "@/views/organization/menterShip.vue";
import Students from "@/views/organization/studentsIntern.vue";
import OrganizationProfile from "@/views/organization/collegeProfile.vue";
import adminInstructors from "@/views/organization/administrators.vue";
import inviteIntern from "@/views/organization/invite.vue";
import courseOrganization from "@/views/organization/courseIntern.vue";
import Events from "@/views/organization/eventPage.vue";
import Organization from "@/views/organization/organizationLayout.vue";
import studentCourseDetails from "@/views/studentDashboard/studentLayout.vue";
import leftNavigationStudent from "@/views/studentDashboard/leftnav.vue";
import assignmentStudent from "@/views/studentDashboard/assignmentStudent.vue";
import chatStudent from "@/views/studentDashboard/chatStudent.vue";
import documentStudent from "@/views/studentDashboard/documentStudent.vue";
import messageStudent from "@/views/studentDashboard/messageStudent.vue";
import sessionStudent from "@/views/studentDashboard/sessionStudent.vue";
import studentEvents from "@/views/studentDashboard/studentEvents.vue";
import UserProfile from "@/views/userProfile.vue";
import SignUpForInvite from "@/views/SignUpForInvite.vue";
import NavigationInstructor from "@/views/instructordashboard/leftnavinstructor.vue";
import InstructorDashboardLayout from "@/views/instructordashboard/Layout.vue";
import InstructorAssignments from "@/views/instructordashboard/assignmentsInstructor.vue";
import InstructorEvents from "@/views/instructordashboard/events.vue";
import InstructorStudents from "@/views/instructordashboard/studentslist.vue";
import AssignmentSubmitted from "@/views/instructordashboard/assignmentSubmitted.vue";
import InstructorQuiz from "@/views/instructordashboard/courseQuiz.vue";
import FlashMessage from "@/components/flashMessage.vue";
import Navigation from "@/components/Navigation.vue";
import confirmPopup from "@/components/confirmPopup.vue";
import InstructorMessage from "@/views/instructordashboard/instructormessage.vue";
import InstructorChat from "@/views/instructordashboard/chatInstructor.vue";
import PasswordReset from "@/views/passwordChange.vue";
import Quizzes from "@/views/quizzes.vue";
import tokenGenerte from "@/views/tokenGenerate.vue";
import contact from "@/views/contact.vue";
import Mycourse from "@/views/MyCourses.vue";
import InstructorHome from "@/views/instructorHome.vue";
import InstructorCourse from "@/views/instructorCourse.vue";
import EditCourse from "@/views/editCourse.vue";
import UpdateLesson from "@/views/updateslesson.vue";
import Blog from "@/views/blog.vue";
import AddBlogFrom from "@/views/add_blog.vue";
import BlogPreview from "@/views/blogPreview.vue";
import BlogListView from "@/views/blogListPage.vue";
import WebinarList from "@/views/webinarListPage.vue";
import WebinarRegister from "@/views/webinarRegister.vue";
import WebinarAdd from "@/views/webinarAdd.vue";
import WebinarListInstructor from "@/views/webinarListInstructor.vue";
import LeadDashboard from "@/views/leadDashboard.vue";
import MyEvents from "@/views/MyEvents.vue";
import StudentQuizView from "@/views/studentDashboard/QuizView.vue";
import KeyTouchPoint from "@/views/KeytouchPoint.vue";
import Enrollment from "@/views/EnrollStudentView.vue";
import StudentAttendance from "@/views/organization/StudentAttendance.vue";
import AttendanceDetail from "@/views/organization/AttendanceDetail.vue";


const routes = [{
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            guest: true
        },
    },
    {
        path: "/payment/:amount/:title/:slug/:courseId",
        name: "Payment",
        component: Payment,
        meta: { requiresAuth: true },
        props: router => ({
            amount: router.params.amount,
            title: router.params.title,
            slug: router.params.slug,
            courseId: router.params.courseId,
        })
    },
    {
        path: "/success/",
        name: "Success",
        component: Success,
        meta: { requiresAuth: true },
        props: router => {
            return {
                razorpay_order_id: router.query.id,
                razorpay_payment_id: router.query.pay_id,
                razorpay_signature: router.query.signature,
                courses_name: router.query.courses_name,
                amount: router.query.amount,
                courseId: router.query.courseId,

            }
        }

    },
    {
        path: "/signup",
        name: "SignUp",
        component: SignUp,
        meta: { guest: true },
    },
    {
        path: '/dashboard',
        name: 'DashboardLayout',
        component: DashboardLayout,
        meta: { requiresAuth: true },
        children: [{
                path: 'home',
                name: 'Dashboard',
                meta: { requiresAuth: true },
                component: Dashboard,
            },
            {
                path: 'profile',
                name: 'DashboardProfile',
                meta: { requiresAuth: true },
                component: DashboardProfile,
            },
            {
                path: 'settings',
                name: 'DashboardSettings',
                meta: { requiresAuth: true },
                component: DashboardSettings,
            },
            {
                path: 'resources',
                name: 'DashboardResource',
                meta: { requiresAuth: true },
                component: DashboardResource,
            },
        ]

    },
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { requiresAuth: true },
    },
    {
        path: "/Home",
        name: "InstructorHome",
        component: InstructorHome,
        meta: { requiresAuth: true },

    },
    {
        path: "/:course_id/edit-course",
        name: "EditCourse",
        component: EditCourse,
        props: router => ({
            course_id: router.params.course_id,
        }),
    },
    {
        path: "/:courseId/edit-lessons",
        name: "UpdateLesson",
        component: UpdateLesson,
        props: router => ({
            courseId: router.params.courseId
        })
    },
    {
        path: "/lead/dashboard",
        name: 'LeadDashboard',
        meta: { requiresAuth: true },
        component: LeadDashboard
    },
    {
        path: "/instructor/course-list",
        name: "InstructorCourse",
        component: InstructorCourse,
        meta: { requiresAuth: true },
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog

    },
    {
        path: '/blog/add-new-blog',
        name: 'AddBlogFrom',
        component: AddBlogFrom
    },
    {
        path: '/blog/:blog_id/preview',
        name: 'BlogPreview',
        component: BlogPreview,
        meta: { requiresAuth: true },
        props: router => ({
            blog_id: router.params.blog_id
        })
    },
    {
        path: "/:orgtoken/:courseid/signup",
        name: "SignUpForInvite",
        component: SignUpForInvite,
        props: router => ({
            orgtoken: router.params.orgtoken,
            courseid: router.params.courseid,
        }),
    },
    {
        path: '/blog-list-view',
        name: 'BlogListView',
        meta: { requiresAuth: true },
        component: BlogListView
    },
    {
        path: '/webinar-list-view',
        name: 'WebinarList',
        meta: { requiresAuth: true },
        component: WebinarList
    },
    {
        path: '/webinar-register/:webinar_id',
        name: 'WebinarRegister',
        meta: { requiresAuth: true },
        component: WebinarRegister,
        props: router => ({
            webinar_id: router.params.webinar_id,
        }),
    },
    {
        path: '/webinar-list-instructor',
        name: 'WebinarListInstructor',
        component: WebinarListInstructor,
        meta: { requiresAuth: true },
    },
    {
        path: '/webinar-add',
        name: 'WebinarAdd',
        component: WebinarAdd,
        meta: { requiresAuth: true },
    },
    {
        path: '/my-event-list',
        name: 'MyEvents',
        component: MyEvents,
        meta: { requiresAuth: true },
    },
    {
        path: "/pricing",
        name: "Pricing",
        component: Pricing,
    },
    {
        path: "/python",
        name: "Python",
        component: Python,
    },
    {
        path: "/rust",
        name: "Rust",
        component: Rust,
    },
    {
        path: "/courses",
        name: "Courses",
        component: Courses,
    },
    {
        path: "/courses/:slug",
        name: "CouseDetail",
        component: CourseDetail,
        meta: { requiresAuth: true },
        props: router => ({
            slug: router.params.slug,
        }),
    },
    {
        path: "/video/:courseid/",
        name: "VideoRender",
        component: VideoRender,
        props: router => ({
            courseid: router.params.courseid,


        }),
        meta: { requiresAuth: true },
    },

    {
        path: '/instructor/create/',
        name: 'InstructorLayout',
        component: InstructorLayout,
        meta: { requiresAuth: true },
        props: router => {
            return {
                title: "Course creation",
                editable: router.params.editable,

            }
        },
        children: [{
                path: 'new-course/',
                name: 'AddCourse',
                component: AddCourse,
                meta: { requiresAuth: true },
            },
            {
                path: 'course',
                name: 'instructorCourse',
                component: instructorCourse,
                meta: { requiresAuth: true },
            },
        ]
    },
    {
        path: '/:course_id/:instructor/:course_name/',
        name: 'InstructorDashboardLayout',
        component: InstructorDashboardLayout,
        meta: { requiresAuth: true },
        props: router => ({
            course_id: router.params.course_id,
            course_name: router.params.course_name,
        }),
        children: [{
                path: 'leftnavigations',
                name: "NavigationInstructor",
                component: NavigationInstructor,
                props: true
            },
            {
                path: "studentlist",
                name: "InstructorStudents",
                component: InstructorStudents,
                props: true
            },
            {
                path: "assignments",
                name: "InstructorAssignments",
                component: InstructorAssignments,
                props: true
            },
            {
                path: "event",
                name: "InstructorEvents",
                component: InstructorEvents,
                props: true
            },
            {
                path: "message",
                name: "InstructorMessage",
                component: InstructorMessage,
                props: true
            },
            {
                path: "forum",
                name: "forum",
                component: InstructorChat,
                props: true
            },
            {
                path: "assignment/submitted/list/:assignment_id",
                name: "AssignmentSubmitted",
                component: AssignmentSubmitted,
                props: router => ({
                    assignment_id: router.params.assignment_id,
                    course_name: router.params.course_name,
                }),
            },
            {
                path: "quiz",
                name: "CourseQuizcreate",
                component: InstructorQuiz,
                props: true
            }
        ]
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/About.vue"),
    },
    {
        path: '/signup-confirmed',
        name: 'Thanks',
        component: Thanks
    },
    {
        path: '/:pagenotfound(.*)*',
        name: 'PageNotFound',
        component: PageNotFound
    },

    {
        path: '/leftnav',
        name: 'leftnav',
        component: LeftNav
    },
    {
        path: '/flashmessage',
        name: 'FlashMessage',
        component: FlashMessage

    },

    {

        path: '/:slug_id/organization/',
        name: 'organization',
        component: Organization,
        meta: { requiresAuth: true },
        children: [{
                path: 'dashboard',
                name: 'organizationDashboard',
                component: OrganizationDashboard
            },
            {
                path: 'leftNavIntern',
                name: 'internLaftNav',
                component: internLaftNav
            },
            {
                path: 'internships',
                name: 'menterShip',
                component: MenterShip
            },
            {
                path: 'learners',
                name: 'students',
                component: Students
            },
            {
                path: 'studentattendance',
                name: 'studentAttendance',
                component: StudentAttendance,
               
            },
            {
                path: '/:slug_id/organization/attendence-detail/:studentId',
                name: 'attendancedetail',
                component: AttendanceDetail,
              },
            {
                path: 'profile',
                name: 'OrganizationProfile',
                component: OrganizationProfile
            },
            {
                path: 'admins',
                name: 'admin',
                component: adminInstructors
            },
            {
                path: 'invite',
                name: 'inviteStudents',
                component: inviteIntern

            },
            {
                path: 'courses',
                name: 'organizationCourse',
                component: courseOrganization
            },
            {
                path: 'events',
                name: 'events',
                component: Events
            }
        ]
    },
    {
        path: '/student-dashboard/:course_id/:instructor_id/:course_name/',
        name: 'studentCourseDetails',
        component: studentCourseDetails,
        meta: { requiresAuth: true },
        props: router => ({
            course_id: router.params.course_id,
            instructor_id: router.params.instructor_id,
            course_name: router.params.course_name,

        }),
        children: [
            {
                path: "task",
                name: "assignmentStudent",
                component: assignmentStudent,
                props: true
            },
            {
                path: "chat",
                name: "chatStudent",
                component: chatStudent,
                props: true
            },
            {
                path: "docs",
                name: "documentStudent",
                component: documentStudent

            },
            {
                path: "messages",
                name: "messageStudent",
                component: messageStudent,
                props: true

            },
            {
                path: "session",
                name: "sessionStudent",
                component: sessionStudent,
                props: true

            },
            {
                path: "events",
                name: "studentEvents",
                component: studentEvents,
                props: true

            },
            {
                path: "quiz-list",
                name: "StudentQuizList",
                component: StudentQuizView,
                props: true

            },

        ]
    },

    {
        path: "/profiles/:selectValue",
        name: "UserProfile",
        component: UserProfile,
        props: router => ({
            selectValue: router.params.selectValue,
            
        }),
        meta: { requiresAuth: true },

    },
    {

        path: '/confirmPopup',
        name: 'ConfirmPopup',
        component: confirmPopup
    },
    {
        path: '/password/:keyword/:keyval',
        name: 'password',
        component: PasswordReset,
        // meta: { requiresAuth: true},
        props: router => ({
            keyword: router.params.keyword,
            keyval: router.params.keyval,
        })

    },
    {
        path: '/quizzes/:quizId',
        name: 'Quizzes',
        component: Quizzes,
        meta: { requiresAuth: true },
        props: router => ({
            quizId: router.params.quizId,
        })
    },
    {
        path: '/resend-email',
        name: 'ResendEmail',
        component: tokenGenerte
    },
    {
        path: '/contact-us',
        name: 'contact',
        component: contact
    },
    {
        path: '/purchased-courses',
        name: 'Mycourse',
        component: Mycourse
    },
    {
        path: "/add-key-objects-touch-points",
        name: "KeyTouchPoint",
        component: KeyTouchPoint,
        meta: { requiresAuth: true },
    },
    {
        path: "/student-enrollment",
        name: "Enrollment",
        component: Enrollment,
        meta: { requiresAuth: true },
    },
  
    


];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {
            top: 10,
            // behavior: 'smooth',
        };
    },
});


router.beforeEach((to, from, next) => {
    if (to.params.title) {
        document.title = ` ${to.params.title}`
    } else {
        document.title = ` ${to.name}`
    }
    // Conditioned for routing to authetication required pages
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            console.log("requiresAuth")
            if (to.path == '/' && store.getters.userInfo.user.permission == 'instructor') {
                next('/Home')
            } else {
                next()
            }
        } else {
            next('/login');
        }
    }
    //Contioned for routing to guest pages
    else if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            next('/')
        } else {
            next();
        }
    }
    // User can have the access to all pages without meta or requiredAuth object
    else {
        next()
    }
})

export default router;