<template>
<div class="mainContentForStudent flex bg-gray-50" style="min-height: calc(100vh - 115px)">
    <leftNavigationStudent actived='quiz' />
    <div class="h-full w-full">
        <h3 class="px-6 mb-0">Quiz List</h3>
        <div v-if="this.quiz_list.length>0" class="flex flex-col gap-2 w-full mx-auto p-2 px-6">
            <div v-for="quiz in this.quiz_list" :key="quiz" class="border h-full w-full px-4 max-w-3xl bg-white rounded-md hover:border-red-800 cursor-pointer">
                <div v-if="quiz.quiz.quiztakers_set.completed" class="flex flex-row gap-2 w-full mx-auto py-3 px-6 justify-between items-center">
                    <p class="capitalize">{{ quiz.quiz.name }}</p>
                    <p class="text-green-500 text-sm flex flex-row justify-end items-center"> Already completed </p>
                    <p class="flex flex-row justify-end items-center text-sm">
                        Score
                        <span class="ml-3 h-10 w-10 text-white rounded-full flex flex-row items-center justify-center text-base font-semibold" style="background-color: #02528d">{{quiz.quiz.quiztakers_set.score}}</span>
                    </p>
                </div>
                <div v-else class="">
                    <router-link :to="{name:'Quizzes',params:{quizId:quiz.quiz.id}}" class="h-20 flex flex-col justify-center">
                        <p class="capitalize">{{ quiz.quiz.name }}</p>
                    </router-link>
                </div>        
            </div>
        </div>
        <div v-else class="text-center p-4 w-full">
            No Quiz available
        </div>
    </div>
</div>
</template>

<script>
import leftNavigationStudent from "@/views/studentDashboard/leftnav.vue";
import axios from "axios";
import { mapActions,mapGetters } from "vuex"
export default {
    data(){
        return {
            quiz_list : []
        }
    },
    computed: mapGetters(["userInfo"]),
    components: {
        leftNavigationStudent,
    },
    props: ["course_id"],
    created(){
        this.quizListCall()
    },
    methods:{
        quizListCall() {
            this.tap='quiz'
            axios.get(`${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    console.log(response)
                    if(response.data.message != 'No Quiz found for this course'){
                        this.quiz_list = response.data.data
                    }else{
                        this.quiz_list = []
                    }

                })
        }
    }

}
</script>

<style>

</style>