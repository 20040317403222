<template>
<div v-if="isAuthenticated && userInfo.user.permission=='student'" data-collapse="medium" data-animation="default" data-duration="1000" data-easing="ease-out-expo" data-easing2="ease-out-expo" data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cc" role="banner" class="header w-nav border-b border-gray-200">
    <div class="container-default-1209px w-container">
        <div class="header-wrapper">
            <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cf" class="split-content header-left">
                <router-link :to="{name:'Home'}" class="brand w-nav-brand w--current">
                    <img src="../assets/myndscope.jpg" alt="myndscope Logo" class="h-14">
                </router-link>
                <nav role="navigation" class="nav-menu w-nav-menu">
                    <!-- <router-link :to="{name:'Home'}" class="nav-link w--current">Home</router-link> -->
                    <!-- <a href="index.html" aria-current="page" class="nav-link w--current">Home</a> -->
                    <!-- <a href="#" class="nav-link">Companies</a> -->
                    <div data-hover="false" data-delay="0" data-w-id="8d41c972-856f-446c-3022-9951bcba0af8" class="header-dropdown w-dropdown">
                        <!-- <div class="header-dropdown-toggle w-dropdown-toggle"> -->
                        <!-- <div class="header-dropdown-toggle w-dropdown-toggle">
                            <div class="nav-link">Pages<span class="dropdown-icon px-2"></span></div>
                        </div> -->
                        <nav class="dropdown-list w-dropdown-list hidden">
                            <div class="menu-grid-wrapper">
                                <div class="w-layout-grid menu-grid ">
                                    <div>
                                        <div class="menu-2-columns">
                                            <div class="mega-menu-column-1">
                                                <router-link :to="{name:'Home'}" class="mega-menu-link w--current">Home</router-link>
                                                <!-- <a href="index.html" aria-current="page" class="mega-menu-link w--current">Home</a> -->

                                                <router-link :to="{name:'Courses'}" class="mega-menu-link">Courses</router-link>
                                                <!-- <a href="https://academytemplate.webflow.io/product/brand-identity-design-for-marketers" class="mega-menu-link">Individual Course</a> -->
                                                <!-- <a href="course/brand-identity-design-for-marketers.html" class="mega-menu-link">Purchased Course</a> -->
                                                <router-link :to="{name:'Mycourse'}" class="mega-menu-link">Purchased Courses</router-link>
                                            </div>
                                            <div class="mega-menu-column-3">
                                                <!-- <a href="blog.html" class="mega-menu-link">Blog</a> -->
                                                <!-- <router-link :to="{name:'BlogListView'}" class="mega-menu-link">Blog Posts</router-link>
                                                <router-link :to="{name : 'WebinarList'}" class="mega-menu-link"> Events</router-link>
                                                <router-link :to="{name : 'MyEvents'}" class="mega-menu-link">My Events</router-link> -->
                                                <!-- <a href="teachers.html" class="mega-menu-link">Teachers</a> -->
                                            </div>
                                            <div class="mega-menu-column-3">
                                                <!-- <a href="https://academytemplate.webflow.io/teacher/john-carter" class="mega-menu-link">Individual Teacher</a> -->
                                                <!-- <a href="contact-us.html" class="mega-menu-link">Contact</a> -->

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    <!-- <router-link :to="{name:'Courses'}" class="nav-link">Developer</router-link> -->
                    <!-- <router-link :to="{name:'About'}" class="mega-menu-link">Companies</router-link> -->
                </nav>
            </div>
            <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a2879930313" class="split-content header-right">
                <router-link :to="{name:'UserProfile',params:{selectValue:'journey'}}" class="w-auto md:w-56 px-0 z-50 flex  justify-center">
                    <div v-if="isAuthenticated && profileInfo != null" class="flex items-center cursor-pointer gap-2">
                        <div class="flex items-center">
                            <div v-if="profileInfo.data.profile==''">
                                <div class="border rounded-full w-10 h-10 self-center text-center flex items-center justify-center text-xl capitalize bg-white font-semibold pb-0">{{profileInfo.data.user.slice(0,1)}}</div>
                            </div>
                            <div v-else>
                                <img :src="profileInfo.data.profile" alt="" class="w-10 h-10 rounded-full border-2 border-white">
                            </div>
                        </div>
                        <div class="font-normal md:font-semibold  text-black lg:text-black capitalize text-xs md:text-base hidden md:flex  ">
                            {{profileInfo.data.user}}
                        </div>
                    </div>
                </router-link>
                <div class="spacer header-right"></div>
                <router-link v-if="!isAuthenticated" :to="{name:'Login'}" class="hidden lg:flex button-primary header-button w-button">Login</router-link>
                <button v-else class="hidden lg:flex button-primary header-button w-button" @click="LogOutModal = !LogOutModal">Logout</button>
                <div data-w-id="63a3d12c-d009-7b48-0e75-aa6a4e6df1c4" class="menu-button lg:hidden">
                    <div @click="dropdownMenu()" class="flex flex-col gap-1.5 justify-center h-full">
                        <div class="border-2 border-gray-600 mx-1"></div>
                        <div class="border-2 border-gray-600 mx-1"></div>
                        <div class="border-2 border-gray-600 mx-1"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="is_dropdown" class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style="height: 6653.35px; display: block;">
        <nav role="navigation" class="nav-menu w-nav-menu " style="transform: translateY(0px) translateX(0px); transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0s;" data-nav-menu-open="">
            <!-- <a href="/" class="nav-link">Home</a> -->
            <!-- <a href="#" class="nav-link">Companies</a> -->
            <div data-hover="false" data-delay="0" data-w-id="8d41c972-856f-446c-3022-9951bcba0af8" class="header-dropdown w-dropdown w--nav-dropdown-open" style="max-width: 1209px;">
                <div @click="dropdownMob()" class="header-dropdown-toggle w-dropdown-toggle w--nav-dropdown-toggle-open" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                    <!-- <div class="nav-link">Pages&nbsp;&nbsp;&nbsp;<span class="dropdown-icon" style=""></span></div> -->
                </div>
                <nav class="dropdown-list w-dropdown-list w--nav-dropdown-list-open hidden" style="" id="w-dropdown-list-mob" aria-labelledby="w-dropdown-toggle-0">
                    <div class="menu-grid-wrapper">
                        <div class="w-layout-grid menu-grid">
                            <div>
                                <div class="menu-2-columns">
                                    <div class="mega-menu-column-1">
                                        <router-link :to="{name:'Home'}" class="mega-menu-link">Home</router-link>
                                        <!-- <router-link :to="{name:'About'}" class="mega-menu-link">About</router-link> -->
                                        <router-link :to="{name:'Courses'}" class="mega-menu-link w--current">Courses</router-link>
                                        <!-- <a href="https://academytemplate.webflow.io/product/brand-identity-design-for-marketers" class="mega-menu-link" tabindex="0">Individual Course</a> -->
                                        <router-link :to="{name:'Mycourse'}" class="mega-menu-link">Purchased Courses</router-link>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="mega-menu-column-4">
                                <h4 class="mega-menu-title">Utility Pages</h4><a href="/utility-pages/style-guide" class="mega-menu-link" tabindex="0">Styleguide</a><a href="https://academytemplate.webflow.io/404" class="mega-menu-link" tabindex="0">404 Not Found</a><a href="https://academytemplate.webflow.io/401" class="mega-menu-link" tabindex="0">Password</a><a href="/utility-pages/licenses" class="mega-menu-link" tabindex="0">Licenses</a><a href="/utility-pages/start-here" class="mega-menu-link" tabindex="0">Start Here</a><a href="/utility-pages/changelog" class="mega-menu-link" tabindex="0">Changelog</a><a href="http://brixtemplates.com/more-templates" class="mega-menu-link special" tabindex="0">Browse More Templates</a>
                            </div> -->
                        </div>
                    </div>
                </nav>
            </div>
            <router-link v-if="!isAuthenticated" :to="{name:'Login'}" class="nav-link flex lg:hidden hover:text-white">Login</router-link>
            <button v-else class="nav-link flex lg:hidden hover:text-white" @click=" LogOutModal = !LogOutModal">Logout</button>
        </nav>
    </div>
    <LogOutPopUp v-if="LogOutModal" @logOut="logOut" @cancel="LogOutModal = !LogOutModal" />
</div>
<!-- instructor -->
<div v-if="isAuthenticated && userInfo.user.permission=='instructor'" data-collapse="medium" data-animation="default" data-duration="1000" data-easing="ease-out-expo" data-easing2="ease-out-expo" data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cc" role="banner" class="header w-nav border-b border-gray-200">
    <div class="container-default-1209px w-container">
        <div class="header-wrapper">
            <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cf" class="split-content header-left">
                <router-link :to="{name:'InstructorHome'}" class="brand w-nav-brand w--current">
                    <img src="../assets/myndscope.jpg" alt="myndscope Logo" class="h-14">
                </router-link>
                <nav role="navigation" class="nav-menu w-nav-menu">
                    <!-- <router-link :to="{name:'InstructorHome'}" class="nav-link w--current">Home</router-link> -->
                    <!-- <a href="index.html" aria-current="page" class="nav-link w--current">Home</a> -->
                    <div data-hover="false" data-delay="0" data-w-id="8d41c972-856f-446c-3022-9951bcba0af8" class="header-dropdown w-dropdown">
                        <!-- <div class="header-dropdown-toggle w-dropdown-toggle"> -->
                        <div class="header-dropdown-toggle w-dropdown-toggle">
                            <div class="nav-link">Pages<span class="dropdown-icon px-2"></span></div>
                        </div>
                        <nav class="dropdown-list w-dropdown-list hidden">
                            <div class="menu-grid-wrapper">
                                <div class="w-layout-grid menu-grid">
                                    <div>
                                        <div class="menu-2-columns">
                                            <div class="mega-menu-column-1">
                                                <router-link :to="{name:'InstructorHome'}" class="mega-menu-link" :class="[this.$router.currentRoute._value.name == 'InstructorHome' ? 'w--current' : '']">Home</router-link>
                                                <!-- <a href="index.html" aria-current="page" class="mega-menu-link w--current">Home</a> -->
                                                <!-- <router-link :to="{name:'Courses'}" class="mega-menu-link">Courses</router-link> -->
                                                <router-link :to="{name:'AddCourse'}" class="mega-menu-link" :class="[this.$router.currentRoute._value.name == 'AddCourse' ? 'w--current' : '']">Add Courses</router-link>
                                                <!-- <router-link :to="{name:'InstructorCourse'}" class="mega-menu-link" :class="[this.$router.currentRoute._value.name == 'InstructorCourse' ? 'w--current' : '']">Update Course</router-link> -->
                                                <router-link :to="{name:'KeyTouchPoint'}" class="mega-menu-link" :class="[this.$router.currentRoute._value.name == 'KeyTouchPoint' ? 'w--current' : '']">Objectives & Points</router-link>
                                                <router-link :to="{name:'Enrollment'}" class="mega-menu-link" :class="[this.$router.currentRoute._value.name == 'Enrollment' ? 'w--current' : '']">Student Enrollment</router-link>

                                            </div>
                                            <div class="mega-menu-column-3">
                                                <!-- <a href="contact-us.html" class="mega-menu-link">Contact</a> -->
                                                <!-- <router-link :to="{name:'About'}" class="mega-menu-link">About</router-link> -->
                                                <!-- <router-link :to="{name:'contact'}" class="mega-menu-link">Contact</router-link> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mega-menu-column-4">
                                        <!-- <h4 class="mega-menu-title">Utility Pages</h4>
                                        <a href="https://academytemplate.webflow.io/401" class="mega-menu-link">Password</a>
                                        <a href="utility-pages/licenses.html" class="mega-menu-link">Licenses</a>
                                        <a href="utility-pages/start-here.html" class="mega-menu-link">Start Here</a>
                                        <a href="utility-pages/changelog.html" class="mega-menu-link">Changelog</a> -->
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </nav>
            </div>
            <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a2879930313" class="split-content header-right">
                <router-link :to="{name:'instructorCourse'}" class="w-auto md:w-56 px-0 z-50 flex  justify-center">
                    <div v-if="isAuthenticated && profileInfo != null" class="flex items-center cursor-pointer gap-2">
                        <div class="flex items-center">
                            <div v-if="profileInfo.data.profile==''">
                                <div class="border rounded-full w-10 h-10 self-center text-center flex items-center justify-center text-xl capitalize bg-white font-semibold ">{{profileInfo.data.user.slice(0,1)}}</div>
                            </div>
                            <div v-else>
                                <img :src="profileInfo.data.profile" alt="" class="w-10 h-10 rounded-full border-2 border-white">
                            </div>
                        </div>
                        <div class="font-normal md:font-semibold  text-black lg:text-black capitalize text-xs md:text-base hidden md:flex  ">
                            {{profileInfo.data.user}}
                        </div>
                    </div>
                </router-link>
                <div class="spacer header-right"></div>

                <router-link v-if="!isAuthenticated" :to="{name:'Login'}" class="hidden lg:flex button-primary header-button w-button">Login</router-link>
                <button v-else class="hidden lg:flex button-primary header-button w-button" @click="LogOutModal = !LogOutModal">Logout</button>
                <div data-w-id="63a3d12c-d009-7b48-0e75-aa6a4e6df1c4" class="menu-button lg:hidden">
                    <div @click="dropdownMenu()" class="flex flex-col gap-1.5 justify-center h-full">
                        <div class="border-2 border-gray-600 mx-1"></div>
                        <div class="border-2 border-gray-600 mx-1"></div>
                        <div class="border-2 border-gray-600 mx-1"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="is_dropdown" class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style="height: 6653.35px; display: block;">
        <nav role="navigation" class="nav-menu w-nav-menu " style="transform: translateY(0px) translateX(0px); transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0s;" data-nav-menu-open=""><a href="/" class="nav-link">Home</a>
            <div data-hover="false" data-delay="0" data-w-id="8d41c972-856f-446c-3022-9951bcba0af8" class="header-dropdown w-dropdown w--nav-dropdown-open" style="max-width: 1209px;">
                <div @click="dropdownMob()" class="header-dropdown-toggle w-dropdown-toggle w--nav-dropdown-toggle-open" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                    <div class="nav-link">Pages&nbsp;&nbsp;&nbsp;<span class="dropdown-icon" style=""></span></div>
                </div>
                <nav class="dropdown-list w-dropdown-list w--nav-dropdown-list-open hidden" style="" id="w-dropdown-list-mob" aria-labelledby="w-dropdown-toggle-0">
                    <div class="menu-grid-wrapper">
                        <div class="w-layout-grid menu-grid">
                            <div>
                                <h4 class="mega-menu-title hidden-mobile">Pages</h4>
                                <div class="menu-2-columns">
                                    <div class="mega-menu-column-1">
                                        <router-link :to="{name:'Home'}" class="mega-menu-link">Home</router-link>
                                        <!-- <router-link :to="{name:'About'}" class="mega-menu-link">About</router-link> -->
                                        <router-link :to="{name:'InstructorCourse'}" class="mega-menu-link w--current">Courses</router-link>
                                        <router-link :to="{name:'Mycourse'}" class="mega-menu-link">Add Course</router-link>
                                        <router-link :to="{name:'Mycourse'}" class="mega-menu-link">Add Lesson</router-link>
                                    </div>
                                    <div class="mega-menu-column-3">
                                        <!-- <router-link :to="{name:'contact'}" class="mega-menu-link">Contact</router-link> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="mega-menu-column-4">
                                <h4 class="mega-menu-title">Utility Pages</h4><a href="/utility-pages/style-guide" class="mega-menu-link" tabindex="0">Styleguide</a><a href="https://academytemplate.webflow.io/404" class="mega-menu-link" tabindex="0">404 Not Found</a><a href="https://academytemplate.webflow.io/401" class="mega-menu-link" tabindex="0">Password</a><a href="/utility-pages/licenses" class="mega-menu-link" tabindex="0">Licenses</a><a href="/utility-pages/start-here" class="mega-menu-link" tabindex="0">Start Here</a><a href="/utility-pages/changelog" class="mega-menu-link" tabindex="0">Changelog</a><a href="http://brixtemplates.com/more-templates" class="mega-menu-link special" tabindex="0">Browse More Templates</a>
                            </div> -->
                        </div>
                    </div>
                </nav>
            </div>
            <router-link v-if="!isAuthenticated" :to="{name:'Login'}" class="nav-link flex lg:hidden hover:text-white">Login</router-link>
            <button v-else class="nav-link flex lg:hidden hover:text-white" @click="LogOutModal = !LogOutModal">Logout</button>
        </nav>
    </div>
    <LogOutPopUp v-if="LogOutModal" @logOut="logOut" @cancel="LogOutModal = !LogOutModal" />
</div>

<!-- Super Admin -->
<div v-if="isAuthenticated && userInfo.user.permission=='super_admin'" data-collapse="medium" data-animation="default" data-duration="1000" data-easing="ease-out-expo" data-easing2="ease-out-expo" data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cc" role="banner" class="header w-nav border-b border-gray-200">
    <div class="max-w-7xl w-full mx-auto">
        <div class="header-wrapper">
            <div class="flex gap-8 justify-center items-end">
                <router-link :to="{name:'organizationDashboard',params:{slug_id:this.userInfo.slug_id}}" class="brand w-nav-brand w--current">
                    <img src="../assets/myndscope.jpg" alt="myndscope Logo" class="h-14">
                </router-link>
                <router-link :to="{name:'organizationDashboard',params:{slug_id:this.userInfo.slug_id}}">
                    <button class="bg-primary px-4 py-1 rounded-md">
                        Dashboard
                    </button>
                </router-link>
            </div>
            

            <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a2879930313" class="split-content header-right">
                <router-link :to="{name:'organizationDashboard',params:{slug_id:this.userInfo.slug_id}}" class="w-auto md:w-56 px-0 z-50 flex  justify-center">
                    <div v-if="isAuthenticated && profileInfo != null" class="flex items-center cursor-pointer gap-2">
                        <div class="flex items-center">
                            <div v-if="profileInfo.data.profile==''">
                                <div class="border rounded-full w-10 h-10 self-center text-center flex items-center justify-center text-xl capitalize bg-white font-semibold ">{{profileInfo.data.user.slice(0,1)}}</div>
                            </div>
                            <div v-else>
                                <img :src="profileInfo.data.profile" alt="" class="w-10 h-10 rounded-full border-2 border-white">
                            </div>
                        </div>
                        <div class="font-normal md:font-semibold  text-black lg:text-black capitalize text-xs md:text-base hidden md:flex  ">
                            {{profileInfo.data.user}}
                        </div>
                    </div>
                </router-link>
                <div class="spacer header-right"></div>

                <router-link v-if="!isAuthenticated" :to="{name:'Login'}" class="hidden lg:flex button-primary header-button w-button">Login</router-link>
                <button v-else class="hidden lg:flex button-primary header-button w-button" @click="LogOutModal = !LogOutModal">Logout</button>
                <div data-w-id="63a3d12c-d009-7b48-0e75-aa6a4e6df1c4" class="menu-button lg:hidden">
                    <div @click="dropdownMenu()" class="flex flex-col gap-1.5 justify-center h-full">
                        <div class="border-2 border-gray-600 mx-1"></div>
                        <div class="border-2 border-gray-600 mx-1"></div>
                        <div class="border-2 border-gray-600 mx-1"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="is_dropdown" class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style="height: 6653.35px; display: block;">
        <nav role="navigation" class="nav-menu w-nav-menu " style="transform: translateY(0px) translateX(0px); transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0s;" data-nav-menu-open=""><a href="/" class="nav-link">Home</a>
            <div data-hover="false" data-delay="0" data-w-id="8d41c972-856f-446c-3022-9951bcba0af8" class="header-dropdown w-dropdown w--nav-dropdown-open" style="max-width: 1209px;">
                <div @click="dropdownMob()" class="header-dropdown-toggle w-dropdown-toggle w--nav-dropdown-toggle-open" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                    <div class="nav-link">Pages&nbsp;&nbsp;&nbsp;<span class="dropdown-icon" style=""></span></div>
                </div>
                <nav class="dropdown-list w-dropdown-list w--nav-dropdown-list-open hidden" style="" id="w-dropdown-list-mob" aria-labelledby="w-dropdown-toggle-0">
                    <div class="menu-grid-wrapper">
                        <div class="w-layout-grid menu-grid">
                            <div>
                                <h4 class="mega-menu-title hidden-mobile">Pages</h4>
                                <div class="menu-2-columns">
                                    <div class="mega-menu-column-1">
                                        <router-link :to="{name:'Home'}" class="mega-menu-link">Home</router-link>
                                        <!-- <router-link :to="{name:'About'}" class="mega-menu-link">About</router-link> -->
                                        <router-link :to="{name:'InstructorCourse'}" class="mega-menu-link w--current">Courses</router-link>
                                        <router-link :to="{name:'Mycourse'}" class="mega-menu-link">Add Course</router-link>
                                        <router-link :to="{name:'Mycourse'}" class="mega-menu-link">Add Lesson</router-link>
                                    </div>
                                    <div class="mega-menu-column-3">
                                        <a href="/blog" class="mega-menu-link" tabindex="0">Blog</a>
                                        <a href="https://academytemplate.webflow.io/post/8-great-design-trends-that-are-coming-back" class="mega-menu-link" tabindex="0">Blog Post</a>
                                        <a href="/events" class="mega-menu-link" tabindex="0">Events</a><a href="https://academytemplate.webflow.io/event/brand-identity-design-qa-with-sophie-moore" class="mega-menu-link" tabindex="0">Individual Event</a>
                                        <a href="/teachers" class="mega-menu-link" tabindex="0">Teachers</a>
                                    </div>
                                    <div class="mega-menu-column-3">
                                        <!-- <router-link :to="{name:'contact'}" class="mega-menu-link">Contact</router-link> -->
                                    </div>
                                </div>
                            </div>
                            <div class="mega-menu-column-4">
                                <h4 class="mega-menu-title">Utility Pages</h4><a href="/utility-pages/style-guide" class="mega-menu-link" tabindex="0">Styleguide</a><a href="https://academytemplate.webflow.io/404" class="mega-menu-link" tabindex="0">404 Not Found</a><a href="https://academytemplate.webflow.io/401" class="mega-menu-link" tabindex="0">Password</a><a href="/utility-pages/licenses" class="mega-menu-link" tabindex="0">Licenses</a><a href="/utility-pages/start-here" class="mega-menu-link" tabindex="0">Start Here</a><a href="/utility-pages/changelog" class="mega-menu-link" tabindex="0">Changelog</a><a href="http://brixtemplates.com/more-templates" class="mega-menu-link special" tabindex="0">Browse More Templates</a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <router-link v-if="!isAuthenticated" :to="{name:'Login'}" class="nav-link flex lg:hidden hover:text-white">Login</router-link>
            <button v-else class="nav-link flex lg:hidden hover:text-white" @click="LogOutModal = !LogOutModal">Logout</button>
        </nav>
    </div>
    <LogOutPopUp v-if="LogOutModal" @logOut="logOut" @cancel="LogOutModal = !LogOutModal" />
</div>

<!-- without Login -->
<div v-if="!isAuthenticated " data-collapse="medium" data-animation="default" data-duration="1000" data-easing="ease-out-expo" data-easing2="ease-out-expo" data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cc" role="banner" class="header w-nav border-b border-gray-200">
    <div class="container-default-1209px w-container">
        <div class="header-wrapper">
            <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cf" class="split-content header-left">
                <router-link :to="{name:'Home'}" class="brand w-nav-brand w--current">
                    <img src="https://ik.imagekit.io/cvarig29wj/WhatsApp.jpeg?updatedAt=1707387701698" alt="myndscope Logo" class="h-14">
                </router-link>
                <!-- <nav role="navigation" class="nav-menu w-nav-menu">
                    <router-link :to="{name:'Home'}" class="nav-link w--current">Home</router-link>
                    <router-link :to="{name:'Courses'}" class="nav-link">Courses</router-link>
                </nav> -->
            </div>
            <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a2879930313" class="split-content header-right">
                <div class="spacer header-right"></div>

                <router-link v-if="!isAuthenticated" :to="{name:'Login'}" class="hidden lg:flex button-primary header-button w-button">Login</router-link>
                <button v-else class="hidden lg:flex button-primary header-button w-button" @click="LogOutModal = !LogOutModal">Logout</button>
                <div data-w-id="63a3d12c-d009-7b48-0e75-aa6a4e6df1c4" class="menu-button lg:hidden">
                    <div @click="dropdownMenu()" class="flex flex-col gap-1.5 justify-center h-full">
                        <div class="border-2 border-gray-600 mx-1"></div>
                        <div class="border-2 border-gray-600 mx-1"></div>
                        <div class="border-2 border-gray-600 mx-1"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="is_dropdown" class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style="height: 6653.35px; display: block;">
        <nav role="navigation" class="nav-menu w-nav-menu " style="transform: translateY(0px) translateX(0px); transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0s;" data-nav-menu-open="">
            <!-- <a href="/" class="nav-link">Home</a> -->
            <router-link v-if="!isAuthenticated" :to="{name:'Login'}" class="nav-link flex lg:hidden hover:text-white">Login</router-link>
            <button v-else class="nav-link flex lg:hidden hover:text-white" @click="LogOutModal = !LogOutModal">Logout</button>
        </nav>
    </div>
    <LogOutPopUp v-if="LogOutModal" @logOut="logOut" @cancel="LogOutModal = !LogOutModal" />
</div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex';
import axios from "axios";
import $ from "jquery";
import LogOutPopUp from '@/components/LogOutPopUp';

export default {
    computed: mapGetters(["isAuthenticated", "userInfo"]),
    components: {
        LogOutPopUp,

    },

    props: {
        active: String,
    },

    data: () => {
        return {
            LogOutModal: false,
            info: null,
            profileInfo: null,
            isOption: false,
            is_dropdown: false

        }
    },

    created() {
        
        if (this.userInfo) {
            console.log('start')
            let token = this.userInfo.token_key;
            let slug_id = this.userInfo.slug_id;
            axios.get(`${process.env.VUE_APP_API}/profile_detail/${slug_id}?Key=${token}&format=json`)
                .then(response => this.profileInfo = response.data)
        } else {
            console.log('Not Login')
        }
    },
    mounted() {

        $(".header-dropdown-toggle").on("click", function (e) {
            e.preventDefault();
            e.stopPropagation();
            $("nav.dropdown-list").toggleClass("hidden");
        });

        $("body").on("click", function (e) {
            if (!$("nav.dropdown-list").hasClass("hidden") && !$(e.target).closest("nav.dropdown-list").length)
                $("nav.dropdown-list").addClass("hidden");
        });

    },

    methods: {
        ...mapActions(["logOutUser"]),

        dropdownMob() {
            document.getElementById('w-dropdown-list-mob').classList.toggle('hidden')
        },

        dropdownMenu() {
            this.is_dropdown = !this.is_dropdown
        },
        async logOut() {
            await this.logOutUser();
            this.LogOutModal = false;
            this.$router.push({
                name: "Login"
            })

        },

        dropDown() {
            this.isOption = !this.isOption;
            document.getElementsByClassName('downArrow')[0].classList.toggle('rotateImg');

        }

    },
}
</script>

<style >

</style>
