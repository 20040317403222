<template>
<div>
    <div class="sm:flex lg:hidden left-0 fixed top-19 z-50">
        <button @click="toggleClassMenu" class="rounded-r-md text-white border border-gray-400 z-50 bg-white w-10 h-10" aria-label="Open Menu" style="z-index: 289;">
            <div class="flex flex-col gap-1.5 justify-center h-full"><div class="border-2 border-gray-600 mx-1"></div><div class="border-2 border-gray-600 mx-1"></div><div class="border-2 border-gray-600 mx-1"></div></div>
        </button>
    </div>

    <div class="layout relative" id="clickfun">
        <!-- 2nd nav -->
        <div class="mv-left-nav absolute left-0 hidden w-full h-full lg:hidden bg-white min-h-screen">
        <div class="relative w-full h-full">
        <div class="h-full flex flex-col justify-start items-start w-full text-gray-100 font-medium bg-white bg-opacity-60" style="z-index: 289;width:100vw">
            <div class="w-44 md:w-52 h-full flex flex-col gap-2 md:gap-3 bg-white border">
                <router-link :to='{name :"organizationDashboard"}' class='cursor-pointer rounded-md'>
                    <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='Dashboard'}">
                        <img src="../../assets/dashboard.svg" class="w-5 h-5" alt="">
                        <div>Dashboard</div>
                    </div>
                </router-link>

                <router-link :to='{name :"organizationCourse"}' class='cursor-pointer'>
                    <div class="w-44 md:w-52 text-left text-sm py-2 hover:bg-teal-700 items-center flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='courses'}">
                        <img src="../../assets/assignment.svg" class="w-5 h-5" alt="">
                        <div>Courses</div>
                    </div>
                </router-link>

                <!-- <router-link :to='{name :"menterShip"}' class='cursor-pointer'>
                    <div class=" hidden w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='Mentership'}">
                        <img src="../../assets/content.svg" class="w-5 h-5">
                        <div>Projects</div>
                    </div>
                </router-link> -->

                <router-link :to='{name :"students"}'>
                    <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='Students'}">
                        <img src="../../assets/studentslist.svg" class="w-5 h-5">
                        <div>Students</div>
                    </div>
                </router-link>

                <router-link :to='{name :"OrganizationProfile"}'>
                    <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='Profile'}">
                        <img src="../../assets/profile.svg" class="w-5 h-5">
                        <div>Mails</div>
                    </div>
                </router-link>

                <router-link :to='{name :"admin"}'>
                    <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='Administrators'}">
                        <img src="../../assets/forums.svg" class="w-5 h-5" alt="">
                        <div>Admins</div>
                    </div>
                </router-link>

                <router-link :to='{name :"inviteStudents"}'>
                    <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='invite'}">
                        <img src="../../assets/invite.svg" class="w-5 h-5" alt="">
                        <div>Invite</div>
                    </div>
                </router-link>

                <!-- <router-link :to='{name :"events"}'>
                    <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='events'}">
                        <img src="../../assets/events.svg" class="w-5 h-5" alt="">
                        <div>Events</div>
                    </div>
                </router-link> -->
            </div>
            <img @click="this.toggleClassMenu" src="../../assets/closebutton.svg" class="absolute h-10 right-0" />
        </div>
        </div>
        </div>
        <!-- left nav for lg screen -->
        <div class="hidden w-48 2xl:w-56 largerLayout h-full lg:flex lg:flex-col justify-start items-start text-black font-medium relative gap-2" >
            <!-- <img src="../../assets/leftArrow.svg" class="w-8 p-1 LeftArrow absolute -right-3.5 top-3.5 z-50 bg-teal-500  hover:bg-teal-600 rounded-lg cursor-pointer" @click="slideFn"/> -->
            <div class="collapseDiv w-48 2xl:w-52 flex items-center bottom-0 cursor-pointer px-4 py-1 gap-2 border rounded-md border-gray-100 shadow-sm mx-auto" @click="slideFn">
                <img src="../../assets/leftArrow.svg" class="w-7 p-1 LeftArrow " />
                <div class="collapseText text-sm">Collapse sidebar</div>
            </div>
            <router-link :to='{name :"organizationDashboard"}' class='cursor-pointer'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='Dashboard'}">
                    <img src="../../assets/dashboard.svg" title="Dashboard" class="w-6 h-6" alt="">
                    <div>Dashboard</div>
                </div>
            </router-link>

            <router-link :to='{name :"organizationCourse"}' class='cursor-pointer'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='courses'}">
                    <img src="../../assets/assignment.svg" title="Courses" class="w-6 h-6" alt="">
                    <div>Courses</div>
                </div>
            </router-link>

            <!-- <router-link :to='{name :"menterShip"}' class='cursor-pointer'>
                <div class=" hidden contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='Mentership'}">
                    <img src="../../assets/content.svg" id="Projects" class="w-6 h-6">
                    <div>Projects</div>
                </div>
            </router-link> -->

            <router-link :to='{name :"students"}'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='Students'}">
                    <img src="../../assets/studentslist.svg" title="Students" class="w-6 h-6">
                    <div>Students</div>
                </div>
            </router-link>

            <router-link :to='{name :"studentAttendance"}'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='StudentAttendance'}">
                    <img src="../../assets/studentslist.svg" title="Students" class="w-6 h-6">
                    <div>Attendance</div>
                </div>
            </router-link>

            <router-link :to='{name :"OrganizationProfile"}'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='Profile'}">
                    <img src="../../assets/profile.svg" title="Profile" class="w-6 h-6">
                    <div>Mails</div>
                </div>
            </router-link>

            <router-link :to='{name :"admin"}'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='Administrators'}">
                    <img src="../../assets/forums.svg" title="Admins" class="w-6 h-6" alt="">
                    <div>Admins</div>
                </div>
            </router-link>

            <router-link :to='{name :"inviteStudents"}'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='invite'}">
                    <img src="../../assets/invite.svg" title="Invite" class="w-6 h-6" alt="">
                    <div>Invite</div>
                </div>
            </router-link>

            <!-- <router-link :to='{name :"events"}'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='events'}">
                    <img src="../../assets/events.svg" title="Events" class="w-6 h-6" alt="">
                    <div>Events</div>
                </div>
            </router-link> -->
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            lefnev: false,
        }
    },
    props: {
        actived: String
    },
    mounted() {
        
    },
    methods: {
        slideFn() {
            document.querySelector(".LeftArrow").classList.toggle("rotateimg");
            document.querySelector(".collapseText").classList.toggle("hidden");
            document.querySelector(".collapseDiv").classList.toggle("border-none");

            var largelayout = document.querySelector(".largerLayout")
            if (!largelayout.classList.contains("app-menu-close")) {
                largelayout.classList.add("app-menu-close");
            } else {
                largelayout.classList.remove("app-menu-close");
            }
        },
        toggleClassMenu() {
             document.querySelector(".mv-left-nav").classList.toggle('hidden')

        }
    }
}
</script>

<style scoped>
.largerLayout {
    transition: width 1.5s, ;
}

.rotateimg {
    transform: rotate(180deg) translate(-5px);
}

.contentval {
    transition: width 1.5s, ;
}


.app-menu-open .leftmenu {
    -webkit-transform: none;
    transform: none;
    transition: transform 100ms linear;
}

.app-menu-close {
    width: 60px;
    transition: width 1.5s, ;
    margin: auto;
}

.app-menu-close .contentval {
    width: 60px;
    padding-left: 0px;
    padding-right: 0px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app-menu-close .contentval div {
    display: none;
}
</style>
