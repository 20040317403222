<template lang="">
<div class="flex">
    <NavigationInstructor actived="quiz" />
    <div class="w-full relative">
        <h1 class="w-full py-1 font-semibold px-2 text-gray-900 bg-white border-gray-300 text-lg 2xl:text-3xl mb-0">{{course_name}}</h1>
        <!-- Breadcrumb -->
        <!-- <div>
            <ol class="flex gap-2 items-center border-b p-2">
                <li v-if="userInfo.user.permission == 'admin' || userInfo.user.permission == 'super_admin'" class="text-gray-500 text-sm">
                    <router-link :to='{name :"organizationCourse",params:{slug_id:this.userInfo.slug_id}}'>
                        Dashboard
                    </router-link> >
                </li>
                <li v-else class="text-gray-500 text-sm">
                    <router-link :to="{name : 'Dashboard'}">
                        Dashboard
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">
                    <router-link :to="{name :'InstructorStudents'}">
                        {{course_name}}
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">

                    Quiz

                </li>
            </ol>
        </div> -->
        <header class="bg-gray-100 w-full max-w-5xl mx-4 rounded-md">
            <div class="w-5/6 px-4 py-2 flex gap-2">
                <button class="border text-sm bg-white px-2 py-1 rounded" @click="quizgetinfo()" :class="[this.is_selected=='list' ? 'border border-teal-500' : 'border border-gray-200']">Quiz list</button>
                <button class="border text-sm bg-white px-2 py-1 rounded" @click="this.is_selected='add'" :class="[this.is_selected=='add' ? 'border border-teal-500' : 'border border-gray-200']">Quiz Add</button>
            </div>
        </header>

        <!-- Quiz List -->
        <div v-if="this.is_selected=='list'" class="relative bg-white w-full max-w-6xl p-0 md:p-8 overflow-y-auto" style="scrollbar-color:#E5E7EB white;height:92vh">
            <div class="flex flex-col  items-start h-full w-full">
                <div v-for="quiz in this.generalquiz" :key="quiz" class=" flex flex-col bg-white w-5/6 min-h-36 m-1 cursor-pointer relative border shadow-md">
                    <div class="flex flex-col md:flex-row gap-2 items-center p-1 h-full w-full p-4" @click="this.quiz_id=quiz.quiz.id">
                        <div class="flex flex-col items-start w-5/6 px-4">
                                <h1 class="text-gray-700 mb-0 py-0 text-lg font-bold hover:text-teal-600">{{quiz.quiz.name}}</h1>
                                <h3 class="text-left text-sm mb-0 px-1 py-1 text-red-500 font-medium">
                                    {{quiz.quiz.coursequizquestion_set.length}} Questions
                                </h3>
                                <p class='text-sm px-1 mb-0'>{{quiz.quiz.description}}</p>
                        </div>
                    </div>

                    <div class="absolute top-2 right-2 flex gap-3">
                        <button @click="deleteQuiz(quiz.quiz.id)" class="border border-red-400 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-red-100 ">
                            <font-awesome-icon class="text-base text-red-400 self-center h-6 w-6" :icon="['fas','trash-alt']" />
                            Delete
                        </button>
                        

                        <div @click="quizget(quiz.quiz.name)" class="self-center mb-1 cursor-pointer rounded-lg flex border justify-center w-12 gap-2 items-center py-1 bg-white hover:bg-green-100" data-v-fa837450="">
                        <div class="flex items-center " data-v-fa837450="">
                            <!-- Download report -->
                            <img class="object-cover object-center h-6 w-6 " src="../../assets/download.png" data-v-fa837450="" data-bs-toggle="tooltip" title="Download"></div>
                            </div>   



                    </div>
                </div>
            </div>

        </div>

        <!-- Quiz Add -->
        <div v-if="this.is_selected=='add'">
            <Quiz :course_id="this.course_id" />
        </div>

        <div v-if="this.quiz_id != 0" class="bg-black">
            <QuizPreview :course_id='this.course_id' :quiz_id="this.quiz_id" @popupClose="this.quiz_id=0" />
        </div>
    </div>
    <div v-if="this.messages.message != ''" class="fixed top-28 border h-20 right-0 w-96 m-4 rounded-lg text-center py-1 text-black bg-white flex flex-col justify-center">
            {{ this.messages.message }}
    </div>
</div>
</template>

<script>
import axios from "axios"
import Quiz from "@/components/Quiz.vue"
import NavigationInstructor from "@/views/instructordashboard/leftnavinstructor.vue";
import QuizPreview from "@/components/quizpreview.vue"
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            is_selected: 'list',
            quiz_id: 0,
            generalquiz: [],
            current_coursename:'',
            scoredetails:'',
            messages : {
                message : '',
                status : '200'
            }
        }
    },
    components: {
        Quiz,
        NavigationInstructor,
        QuizPreview
    },
    props: ["course_id", "course_name"],
    computed: {
        ...mapGetters(["userInfo"]),

    },
    created() {
        console.log(this.course_name)
        this.quizgetinfo()
    },

    methods: {
        deleteQuiz(id){
            axios.delete(`${process.env.VUE_APP_API}/unit_coursedeleted/${id}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    if(response.data.message=='This data deleted successfully'){
                        this.messages.message = 'Deleted Successfully'
                        this.messages.type = 200
                        this.quizgetinfo()
                    }else{
                        this.messages.message = 'Error'
                        this.messages.type = 400
                    }
                })
                setTimeout(()=>{
                    this.messages.message = ''
                    this.messages.type = '200'
                },3000)
        },
        async quizget(course_name) {
            // console.log("course_namecourse_namecourse_name::",course_name)
            // https://api.wish2skill.maggana.com/coursequizdownloadattemptcount/41/JS,%20HTML%20and%20CSS%20MCQ/
            // console.log("dataaaaaaaaaaaaaaaa",`${process.env.VUE_APP_API}/coursequizdownloadattemptcount/${this.course_id}/${course_name}/?Key=${this.userInfo.token_key}`)
            await axios.get(`${process.env.VUE_APP_API}/coursequizdownloadattemptcount/${this.course_id}/${course_name}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    const link = document.createElement('a')
                    link.href = `${process.env.VUE_APP_API}/coursequizdownloadattemptcount/${this.course_id}/${course_name}/?Key=${this.userInfo.token_key}`
                    link.setAttribute('download', 'filetest.xlsx')
                    document.body.appendChild(link)
                    link.click()             
                    // console.log("aftorlink::",link)

                })
        },        
        async quizgetscoreinfo(coursename) {
            this.is_selected = 'score'
            // console.log("158 :: coursename::",coursename)
            this.current_coursename = coursename
            // console.log(`${process.env.VUE_APP_API}/coursequizattemptcount/${this.course_id}/${coursename}/?Key=${this.userInfo.token_key}`)
            await axios.get(`${process.env.VUE_APP_API}/coursequizattemptcount/${this.course_id}/${coursename}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    if(response.data.data){
                        this.scoredetails = response.data
                        console.log("this.generalquiz",this.scoredetails)
                        // this.published = this.generalquiz[0].quiz.qualify_certificate
                        // console.log("this.generalquiz::",this.generalquiz[0].quiz.qualify_certificate)
                    }

                })
        },          
        async quizgetinfo() {
            this.is_selected = 'list'
            await axios.get(`${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    this.generalquiz = response.data.data

                })
        }
    },
    
    async created (){

        this.is_selected = 'score'
            this.current_coursename = coursename
            // console.log(`${process.env.VUE_APP_API}/coursequizattemptcount/${this.course_id}/${coursename}/?Key=${this.userInfo.token_key}`)
            await axios.get(`${process.env.VUE_APP_API}/coursequizattemptcount/${this.course_id}/${coursename}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    if(response.data.data){
                        this.scoredetails = response.data
                        console.log("this.generalquiz",this.scoredetails)
                        // this.published = this.generalquiz[0].quiz.qualify_certificate
                        // console.log("this.generalquiz::",this.generalquiz[0].quiz.qualify_certificate)
                    }

                });


    }

}
</script>

<style scoped>

</style>
